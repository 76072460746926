import axiosInstance from "../../utils/axios";
import {GETPATIENTS, PATIENTS} from "../endpoints/endpoints";
import {IAttendance} from "../enums/attendances";

type IResponse<T> = {
    success: boolean
    data: T
    message: any
}
export const createAttendance = async (payload: IAttendance): Promise<IResponse<IAttendance>> => {
    try {
        const response = await axiosInstance.post(PATIENTS, payload);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const getAttendancesWithUserId = async (user_id: string): Promise<IResponse<IAttendance[]>> => {
    try {
        const response = await axiosInstance.get(GETPATIENTS(user_id));
        return response.data;
    } catch (error: any) {
        throw error;
    }
};
