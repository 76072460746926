import moment from "moment";
import axiosInstance from "../../utils/axios";
import {RanemId} from "../../pages/home";

export async function getAvailabilityHours(date?: Date): Promise<[Date, boolean][] | undefined> {
    const queryParameters = {
        date: moment(date || new Date()).format('y-MM-DD'),
    };
    const uri = `availability_hours/${RanemId}`;

    try {
        const response = await axiosInstance.get(uri, {params: queryParameters});
        if (response.data.success === true) {
            return response.data?.data;
        } else {
            console.error(response?.data?.message)
        }
    } catch (error: any) {
        console.error(error?.message)
    }
}
