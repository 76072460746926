import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/globals.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ToastContainer} from "react-toastify";
import {Box, ThemeProvider} from "@mui/material";
import {persistor, store} from "./store";
import {theme} from "./utils/theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {APPOINTMENTS_ROUTE, DETAILS_ROUTE, LOGIN_ROUTE, PATIENTS_ROUTE, REGISTER_ROUTE} from "./utils/endpoints/routes";
import Home from "./pages/home";
import Login from "./pages";
import Register from "./pages/register";
import Appointments from "./pages/appointments";
import Details from "./pages/details";
import Attendances from "./pages/attendances";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Box sx={{backgroundColor: 'var(--backgroundGray)'}}>
                    <BrowserRouter>
                        <Routes>
                            <Route index element={<Home/>}/>
                            <Route path={LOGIN_ROUTE} element={<Login/>}/>
                            <Route path={REGISTER_ROUTE} element={<Register/>}/>
                            <Route path={APPOINTMENTS_ROUTE} element={<Appointments/>}/>
                            <Route path={DETAILS_ROUTE} element={<Details/>}/>
                            <Route path={PATIENTS_ROUTE} element={<Attendances/>}/>
                        </Routes>
                    </BrowserRouter>
                </Box>
                <ToastContainer/>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
