import psychologistImage from '../images/pexels-shvets-production-7176036.jpg'
import {Box, Grid} from '@mui/material'
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import {currencySymbol, ranemEmail} from '../utils/endpoints/consts/global'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BookCounselorDialog from "../components/BookCounselorDialog";
import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {RootState} from '../store'
import {LOGIN_ROUTE} from '../utils/endpoints/routes'
import {getRanem} from '../utils/services/ranem'
import awesomeAlert from '../utils/functions/alert'
import {AlertTypeEnum} from '../utils/enums/alertType'
import {useNavigate} from "react-router-dom";
import HomeLayout from "../components/layouts/HomeLayout";

export type IRanemInfo = {
    rate?: number,
    description?: { en: string },
    name?: { en: string }
    available?: boolean,
    price?: number,
    discount_price?: string,
}

export const RanemId = "1"
export default function Home() {
    const token = useSelector((state: RootState) => state.auth?.token);
    const navigate = useNavigate();
    const [ratingValue, setRatingValue] = useState(5);
    const [ranemInfo, setRanemInfo] = useState<IRanemInfo>({});
    const [descriptionText, setDescriptionText] = useState<string>("");
    const PriceFormatter = (price: string | number, code: string) =>
        price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GBP',
        })

    useEffect(() => {
        if (!token) navigate(LOGIN_ROUTE);
    }, [token]);
    useEffect(() => {
        getRanem(RanemId)
            .then((res) => {
                if (res.success === true) {
                    setRanemInfo(res.data);
                } else {
                    awesomeAlert({msg: res.message[0][0], type: AlertTypeEnum.error});
                }
            })
            .catch((error) => {
                awesomeAlert({
                    msg: error?.response?.data?.message,
                    type: AlertTypeEnum.error
                })
            });
    }, [])
    useEffect(() => {
        // console.log(ranemInfo);
        if (ranemInfo?.rate)
            setRatingValue(ranemInfo?.rate)

        const htmlString = ranemInfo.description?.en || '';

        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(htmlString, 'text/html');
        const textContent = parsedHtml.documentElement.textContent || "";

        setDescriptionText(textContent);
    }, [ranemInfo]);

    return (
        <HomeLayout>
            <Box sx={{position: 'relative'}}>
                <Box sx={{width: '100%', height: '40vh', overflow: 'hidden'}}>
                    <img
                        src={psychologistImage}
                        alt="psychologist"
                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                    />
                </Box>
                <Box sx={{maxWidth: '700px', margin: '-100px auto 55px'}}>
                    <Box sx={{width: '100%', position: 'absolute', marginTop: '-50px', maxWidth: '700px'}}>
                        <Box sx={{
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            margin: '0 25px',
                            borderRadius: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            boxShadow: 'var(--global-box-shadow)'
                        }}>
                            <Grid container spacing={2} sx={{width: '100%', margin: '0 auto'}}>
                                <Grid item xs={8} sx={{padding: '5px 0'}}>
                                    <Box sx={{textAlign: 'left', color: 'primary.main'}}>
                                        {/* Ranem Yatakan */}
                                        {ranemInfo.name ? ranemInfo.name.en : "Ranem Yatakan"}
                                    </Box>
                                    <Box sx={{height: '25px'}}></Box>
                                    <Box
                                        sx={{
                                            width: 200,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Rating
                                            name="text-feedback"
                                            value={ratingValue}
                                            readOnly
                                            precision={0.5}
                                            sx={{fontSize: '15px'}}
                                            emptyIcon={<StarIcon sx={{opacity: 0.55}} fontSize="inherit"/>}
                                        />
                                    </Box>
                                    <Box sx={{textAlign: 'left', color: 'gray', fontSize: '12px'}}>ratings (5)</Box>
                                </Grid>
                                <Grid item xs={4} sx={{padding: '5px'}}>
                                    <Box sx={{
                                        backgroundColor: ranemInfo.available ? 'primary.main' : 'lightgray',
                                        borderRadius: '50px',
                                        color: ranemInfo.available ? 'white' : 'gray',
                                        fontSize: '12px',
                                        marginTop: '5px'
                                    }}>
                                        {/* offline */}
                                        {ranemInfo.available ? "online" : "offline"}
                                    </Box>
                                    <Box sx={{
                                        color: 'gray',
                                        fontSize: '20px',
                                        padding: '5px',
                                        textDecoration: 'line-through'
                                    }}>
                                        {ranemInfo.price ? PriceFormatter(ranemInfo.price, currencySymbol) : '0.0'}
                                    </Box>
                                    <Box sx={{color: '#757ce8', fontSize: '20px'}}>
                                        {ranemInfo.discount_price ? PriceFormatter(ranemInfo.discount_price, currencySymbol) : '0.0'}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '75px', padding: '80px 25px 0'}}>
                        <Box sx={{
                            backgroundColor: '#FFB6C1',
                            width: 'fit-content',
                            padding: '2px 10px',
                            m:3,
                            borderRadius: '50px',
                            color: '#DC143C'
                        }}>Psychologist</Box>
                    </Box>
                    <Box sx={{marginTop: '20px', padding: '0 25px'}}>
                        <Box sx={{
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)'
                        }}>
                            <Box sx={{fontWeight: 'bold', padding: '15px 0', color: 'primary.main'}}>Description</Box>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Box sx={{fontSize: '14px', padding: '15px 0', color: 'gray'}}>

                                {ranemInfo.description ? descriptionText : "I'm a certified lifecoach & psychotherapist specializing in inBoxidual and relationship therapy. My mission is to help people live happier and more fulfilling lives."}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '20px', padding: '0 25px'}}>
                        <Box sx={{
                            backgroundColor: '#fff',
                            padding: '15px 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)'
                        }}>
                            <Box sx={{fontWeight: 'bold', color: 'primary.main'}}>Contact Us</Box>
                            <Box sx={{textAlign: 'center'}}>
                                <Grid container spacing={2} sx={{
                                    fontSize: '14px',
                                    color: 'gray',
                                    width: '100%',
                                    margin: '0',
                                    justifyContent: 'space-between'
                                }}>
                                    <Grid item xs={8} sx={{width: 'fit-content', textAlign: 'left'}}>
                                        <Box>
                                            If you have any question!
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sx={{width: 'fit-content', textAlign: 'left'}}>
                                        <Box sx={{textAlign: 'right'}}>
                                            <a
                                                href={`mailto:${ranemEmail}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                style={{
                                                    backgroundColor: '#dbdeff',
                                                    color: '#757ce8',
                                                    width: 'fit-content',
                                                    padding: '14px 7px 4px',
                                                    borderRadius: '5px',
                                                    minWidth: 'fit-content'
                                                }}
                                            >
                                                <MailOutlineIcon/>
                                            </a>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{position: 'relative', marginTop: '10px', padding: '15px 25px', borderRadius: '20px'}}>
                        <BookCounselorDialog/>
                    </Box>
                </Box>
            </Box>
        </HomeLayout>
    )
}
