import Navbar from "../Navbar";
import React from "react";

type LayoutProps = {
    children: React.ReactNode;
};
const HomeLayout = ({children}: LayoutProps): JSX.Element => {

    return (
        <>
            {children}
            <Navbar/>
        </>
    );
};

export default HomeLayout;
