import {Grid} from '@mui/material';
import {theme} from "../utils/theme";
import {lightBorder} from "./BookCounselorDialog";
import AddAttendance from './AddAttendance'

const CreateAttendance = (props: { haveAttendance: Boolean }): JSX.Element => {

    return (<>
            {
                props.haveAttendance ?
                    <Grid item spacing={2} sx={{
                        ...lightBorder,
                        backgroundColor: '#fff',
                        padding: '15px',
                        borderRadius: theme.shape.borderRadius,
                        width: '100%',
                        margin: '0',
                        fontWeight: 'bold'
                    }}>
                        You already have a attendance
                    </Grid>
                    :
                    <Grid container spacing={2} sx={{
                        ...lightBorder,
                        backgroundColor: '#fff',
                        padding: '15px',
                        borderRadius: theme.shape.borderRadius,
                        width: '100%',
                        margin: '0',
                        fontWeight: 'bold'
                    }}>
                        <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>Create Attendance</Grid>
                        <Grid item xs={4} sx={{textAlign: 'end'}}>
                            <AddAttendance/>
                        </Grid>
                    </Grid>
            }
        </>
    );
}


export default CreateAttendance;
