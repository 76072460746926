import axiosInstance from "../../utils/axios";
import {GETDOCTOR} from "../endpoints/endpoints";

export const getRanem = async (id: string) => {
    try {
        const response = await axiosInstance.get(GETDOCTOR(id));
        return response.data;
    } catch (error: any) {
        throw error;
    }
}
