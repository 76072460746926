import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUser} from "../../utils/interfaces/user";

interface AuthState {
    token: string | null;
    token2: string | null;
    user: IUser | null;
    user_id: string | null;
}

const initialState: AuthState = {
    token: null,
    token2: null,
    user: null,
    user_id: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state: AuthState, action: PayloadAction<string | null>) {
            state.token = action.payload;
        },
        setToken2(state: AuthState, action: PayloadAction<string | null>) {
            state.token2 = action.payload;
        },
        setUser(state: AuthState, action: PayloadAction<IUser | null>) {
            state.user = action.payload;
        },
        setUserId(state: AuthState, action: PayloadAction<string | null>) {
            state.user_id = action.payload;
        }
    },
});

export const {setToken, setToken2, setUser} = authSlice.actions;
export default authSlice.reducer;
