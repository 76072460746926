import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvUtY4Oa1ofUUdC7redCZdgvRfDgLSejE",
  authDomain: "ranem-504fd.firebaseapp.com",
  databaseURL: "https://ranem-504fd-default-rtdb.firebaseio.com",
  projectId: "ranem-504fd",
  storageBucket: "ranem-504fd.appspot.com",
  messagingSenderId: "61891016341",
  appId: "1:61891016341:web:21c70b04a8544efc538d9c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
