// import {toast} from "react-toastify";
// import {AlertTypeEnum} from "../enums/alertType";

// interface IAlert {
//     msg: string,
//     type?: AlertTypeEnum
// }

// const awesomeAlert = ({msg = '', type = AlertTypeEnum.success}: IAlert) => {
//     if (type === AlertTypeEnum.success) {
//         toast.success(msg)
//     } else if (type === AlertTypeEnum.error) {
//         toast.error(msg)
//     } else if (type === AlertTypeEnum.info) {
//         toast.info(msg)
//     } else {
//         toast(msg)
//     }

// }

// export default awesomeAlert
import Swal from 'sweetalert2';
import {AlertTypeEnum} from "../enums/alertType";
interface IAlert {
    msg: string,
    type?: AlertTypeEnum
}
const awesomeAlert = ({ msg = '', type = AlertTypeEnum.success }: IAlert) => {
    if (type === AlertTypeEnum.success) {
      Swal.fire('Success', msg, 'success');
    } else if (type === AlertTypeEnum.error) {
      Swal.fire('Error', msg, 'error');
    } else if (type === AlertTypeEnum.info) {
      Swal.fire('Info', msg, 'info');
    } else {
      Swal.fire('Notification', msg, 'info');
    }
  };

  export default awesomeAlert;
