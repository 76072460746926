import {RootState} from "../store";
import {LOGIN_ROUTE} from "../utils/endpoints/routes";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAttendancesWithUserId} from "../utils/services/attendances";
import awesomeAlert from "../utils/functions/alert";
import {AlertTypeEnum} from "../utils/enums/alertType";
import AttendanceCard from "../components/AttendanceCard";
import {IAttendance} from "../utils/enums/attendances";
import {useNavigate} from "react-router-dom";
import HomeLayout from "../components/layouts/HomeLayout";

const Attendances = () => {
    const [myAttendances, setMyAttendances] = useState<IAttendance[]>([]);
    const user_id = useSelector((state: RootState) => state.auth.user?.id);
    const token = useSelector((state: RootState) => state.auth.token);
    const navigate = useNavigate();

    const getAttendances = () => {
        if (user_id) {
            getAttendancesWithUserId(user_id)
                .then((res) => {
                    if (res.success) {
                        setMyAttendances(res.data);
                        // awesomeAlert({msg: res.message});
                    } else {
                        awesomeAlert({msg: res.message[0][0], type: AlertTypeEnum.error});
                    }
                    // console.log("p: "+myAttendances);
                })
                .catch((error) => {
                    // Handle login error here
                    awesomeAlert({
                        msg: error?.response?.data?.message,
                        type: AlertTypeEnum.error
                    })
                });
        }
    };

    // no token => go to login
    useEffect(() => {
        if (!token) navigate(LOGIN_ROUTE);
    }, [token]);

    // Call getUserID when the component mounts
    useEffect(() => {
        getAttendances();
    }, [user_id]);

    return (
        <HomeLayout>
            <Box sx={{maxWidth: '650px', margin: '0 auto'}}>
                <Box sx={{
                    textAlign: 'center',
                    color: 'primary.main',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '20px 0',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    backgroundColor: 'var(--backgroundGray)',
                    zIndex: '1',
                    maxWidth: '650px'
                }}>Attendances</Box>
                <Box sx={{padding: '20px 25px', margin: '40px 0 35px'}}>
                    {myAttendances.map((attendance, i) => (
                        <AttendanceCard key={i} attendanceData={myAttendances[i]}/>
                    ))}
                </Box>
            </Box>
        </HomeLayout>
    );
};

export default Attendances;
