import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {APPOINTMENTS_ROUTE, LOGIN_ROUTE} from "../utils/endpoints/routes";
import {useEffect, useState} from 'react';
import {getAppointment} from "../utils/services/appointments";
import {AlertTypeEnum} from "../utils/enums/alertType";
import englandBridge from '../images/engalnd.jpg'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {currencySymbol, ranemEmail} from "../utils/endpoints/consts/global";
import GoToCheckout from "../components/GoToCheckout";
import {useLocation, useNavigate} from "react-router-dom";
import HomeLayout from "../components/layouts/HomeLayout";
import moment from "moment";

export type IMyAppointment = {
    id: number,
    appointment_at: string,
    appointment_status_id: number,
    taxes: string[],
    payment_id: string,
    hint: string,
    attendance: {
        first_name: string,
        last_name: string,
    },
    ranem: {
        name: {
            en: string
        },
        price: number,
        discount_price: number
    }
}
const Details = () => {
    const token = useSelector((state: RootState) => state.auth?.token);
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const appointment_id = query.get('appointment_id');
    // const popRef = useRef<HTMLDivElement>(null)

    const [myAppointment, setMyAppointment] = useState<IMyAppointment>();
    // const [openPopup, setOpenPopup] = useState(false);
    const taxAmount = myAppointment?.taxes ? myAppointment?.taxes[0] : "-";
    const time = moment(myAppointment?.appointment_at).format('HH:mm');
    const day = moment(myAppointment?.appointment_at).format('DD');
    const month = moment(myAppointment?.appointment_at).format('MMMM');
    const year = moment(myAppointment?.appointment_at).format('YYYY');

    useEffect(() => {
        if (!token) navigate(LOGIN_ROUTE);
    }, [token]);

    useEffect(() => {
        if (appointment_id) {
            getAppointment(appointment_id as string)
                .then((res) => {
                    if (res.success === true) {
                        // console.log(res.data);
                        setMyAppointment(res.data);
                        // awesomeAlert({msg: res.message});
                    } else {
                        console.error({msg: res.message[0][0], type: AlertTypeEnum.error});
                    }
                })
                .catch((error) => {
                    console.error({
                        msg: error?.response?.data?.message,
                        type: AlertTypeEnum.error
                    })
                })
        }
    }, [appointment_id])

    const status = () => {
        let statusString = ''
        switch (myAppointment?.appointment_status_id) {
            case 1:
                statusString = "Received";
                break;
            case 10:
                statusString = "Accepted";
                break;
            case 50:
                statusString = "Done";
                break;
            case 60:
                statusString = "Failed";
                break;
            case 7:
                statusString = "Failed";
                break;
        }
        return statusString
    }

    // const handleCancel = () => {
    //     // console.log(appointment_id);
    //     cancelAppointment(appointment_id as string, {cancel: true})
    //         .then((res) => {
    //             if (res.success) {
    //                 // console.log(res.data);
    //                 awesomeAlert({msg: res.message});
    //             } else {
    //                 awesomeAlert({msg: res.message[0][0], type: AlertTypeEnum.error});
    //             }
    //         })
    //         .catch((error) => {
    //             console.error({
    //                 msg: error?.response?.data?.message,
    //                 type: AlertTypeEnum.error
    //             })
    //         })
    //         .finally(() => {
    //             setOpenPopup(false);
    //             navigate(APPOINTMENTS_ROUTE);
    //         })
    // }

    return (
        <HomeLayout>
            <Box sx={{position: 'relative'}}>
                {/*               {openPopup ? (
                    <Box sx={{
                        background: '#0005',
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 4
                    }}>
                        <Box ref={popRef} sx={{
                            backgroundColor: '#fff',
                            maxWidth: '90%',
                            width: '550px',
                            height: '200px',
                            margin: '100px auto',
                            borderRadius: '8px',
                            padding: '25px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            Are you sure you want to delete the appointment?
                            <Grid container spacing={2} sx={{paddingTop: '25px'}}>
                                <Grid item xs={6}>
                                    <Button onClick={handleCancel} variant="contained" color="error">yes</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" onClick={() => setOpenPopup(false)}>No</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ) : null}*/}
                <Box sx={{width: '100%', height: '40vh', overflow: 'hidden'}}>
                    <img
                        src={englandBridge}
                        alt="psychologist"
                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                    />
                </Box>
                <Box sx={{maxWidth: '700px', margin: '-100px auto 55px'}}>
                    <Box sx={{width: '100%', position: 'absolute', marginTop: '-50px', maxWidth: '700px'}}>
                        <Box sx={{
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            margin: '0 25px',
                            borderRadius: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            boxShadow: 'var(--global-box-shadow)'
                        }}>
                            <Grid container spacing={0} sx={{width: '100%', margin: '0 auto', padding: '11px 0'}}>
                                <Grid item xs={8} sx={{padding: '5px 0'}}>
                                    <Box sx={{textAlign: 'left', color: 'primary.main'}}>
                                        {/* Ranem Yatakan */}
                                        {/* {ranemInfo.name? ranemInfo.name.en: "Ranem Yatakan"} */}
                                        {`${myAppointment?.attendance?.first_name} ${myAppointment?.attendance?.last_name}`}
                                    </Box>
                                    <Box>
                                    </Box>
                                    <Box sx={{
                                        textAlign: 'left',
                                        color: 'gray',
                                        fontSize: '12px',
                                        margin: '10px 0 15px'
                                    }}>
                                        Online
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sx={{padding: '5px', height: 'max-content'}}>
                                    <Box sx={{
                                        backgroundColor: 'primary.main',
                                        color: '#fff',
                                        borderRadius: '15px',
                                        padding: '10px',
                                        height: '100%',
                                        Bottom: '16px',
                                        fontWeight: 'normal',
                                        fontSize: '14px'
                                    }}>
                                        {time} <br/> {day} <br/> {month}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '95px', padding: '65px 25px 0'}}>
                        <Box sx={{
                            marginTop: '40px',
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)'
                        }}>
                            <Grid container sx={{
                                fontWeight: 'bold',
                                padding: '15px 0',
                                color: 'primary.main',
                                justifyContent: 'space-between'
                            }}>
                                <Grid item>
                                    Contact Counselor
                                </Grid>
                                <Grid item>
                                    <a
                                        href={`mailto:${ranemEmail}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{
                                            backgroundColor: '#dbdeff',
                                            color: '#757ce8',
                                            width: 'fit-content',
                                            padding: '5px 7px',
                                            borderRadius: '5px',
                                            minWidth: 'fit-content',
                                            margin: '0 5px'
                                        }}
                                    >
                                        <MailOutlineIcon/>
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{
                            marginTop: '25px',
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)',
                            fontSize: '14px'
                        }}>
                            <Grid container spacing={0} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{fontWeight: 'bold', padding: '15px 0', color: 'primary.main'}}>
                                    Appointment Details
                                </Grid>
                                <Grid item sx={{padding: '0 5px'}}>
                                    {`#${appointment_id}`}
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Status
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {status()}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Payment status
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {myAppointment?.payment_id ? 'Paid' : 'Not paid'}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Hint
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {myAppointment?.hint || ''}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{
                            marginTop: '25px',
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)',
                            fontSize: '14px'
                        }}>
                            <Grid container spacing={0} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{fontWeight: 'bold', padding: '15px 0', color: 'primary.main'}}>
                                    Appointment Date & Time
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Appointment at
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {`${day}, ${month} ${year} \u00A0 ${time}`}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{
                            marginTop: '25px',
                            backgroundColor: '#fff',
                            padding: '0 25px',
                            borderRadius: '20px',
                            boxShadow: 'var(--global-box-shadow)',
                            fontSize: '14px'
                        }}>
                            <Grid container spacing={0} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{fontWeight: 'bold', padding: '15px 0', color: 'primary.main'}}>
                                    Pricing
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    {myAppointment?.ranem?.name?.en || ""}
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {myAppointment?.ranem?.discount_price.toFixed(2) || ""} {currencySymbol}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Tax Amount
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {taxAmount || "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Subtotal
                                </Grid>
                                <Grid item>
                                    <Box sx={{backgroundColor: '#eee', borderRadius: '15px', padding: '0 15px'}}>
                                        {myAppointment?.ranem?.discount_price.toFixed(2) || ""} {currencySymbol}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{backgroundColor: '#ddd', height: '1px'}}></Box>
                            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item sx={{padding: '15px 0', color: 'gray'}}>
                                    Total amount
                                </Grid>
                                <Grid item>
                                    <Box sx={{color: 'primary.main', fontSize: '16px', fontWeight: 'bold'}}>
                                        {myAppointment?.ranem?.discount_price.toFixed(2) || ""} {currencySymbol}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Box sx={{marginTop: '25px' , padding: '15px 25px', borderRadius: '20px'}}> */}
                    {/* <Button sx={{backgroundColor: '#0063cc'}} variant="contained">hu</Button> */}
                    {/* <ColorButton sx={{width: '100%'}} variant="contained">Book Counselor</ColorButton> */}
                    {/* </Box> */}

                    <Box sx={{position: 'relative', marginTop: '10px', padding: '15px 25px', borderRadius: '20px'}}>
                        {/* Rest of the code */}

                        {/* Add the GoToCheckout component */}
                        <Grid container>
                            <Grid item xs={true}>
                                {
                                    !!myAppointment &&
                                    <GoToCheckout myAppointment={myAppointment} taxAmount={taxAmount}/>
                                }
                            </Grid>
                            <Grid item xs={2} sx={{textAlign: 'center'}}>
                                <Button variant="contained" sx={{
                                    backgroundColor: "#ddd",
                                    color: '#777',
                                    '&:hover': {
                                        backgroundColor: '#777',
                                        color: '#fff'
                                    }
                                }} color="inherit" onClick={() => navigate(APPOINTMENTS_ROUTE)}>Cancel</Button>
                            </Grid>
                        </Grid>
                        {/* Rest of the code */}
                    </Box>
                </Box>
            </Box>
        </HomeLayout>
    )
}

export default Details;
