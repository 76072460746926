import {Box, Button} from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "../store";
import {setToken} from "../store/auth/authSlice";
import {APPOINTMENTS_ROUTE, HOME_ROUTE, LOGIN_ROUTE, PATIENTS_ROUTE} from "../utils/endpoints/routes";
import {Link, useLocation} from "react-router-dom";

enum RouteEnum {
    Home = '/',
    Appointments = '/appointments',
    Attendances = '/attendances',
}

const inactiveStyle = {
    color: '#aaadde',
    width: 'fit-content',
    borderRadius: '5px',
    minWidth: 'fit-content',
    textTransform: 'capitalize'
}
const activeStyle = {
    color: 'primary',
    borderRadius: '5px',
    justifyContent: 'center',
    backgroundColor: '#dbdeff',
    alignContent: 'center',
    textTransform: 'capitalize',
    width: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: "flex"
}

const Navbar = () => {
    const location = useLocation();
    const token = useSelector((state: RootState) => state.auth?.token);
    const dispatch = useDispatch();
    const selected = location.pathname
    const isSelected = (route: RouteEnum) => selected.toLowerCase() === route.toLowerCase()
    const logout = () => {
        dispatch(setToken(null));
        window.location.href = LOGIN_ROUTE;
    }

    const routes = [
        {
            route: HOME_ROUTE,
            enum: RouteEnum.Home,
            icon: <HomeOutlinedIcon/>,
            text: "Home"
        },
        {
            route: APPOINTMENTS_ROUTE,
            enum: RouteEnum.Appointments,
            icon: <AssignmentOutlinedIcon/>,
            text: "Appointment"
        },
        {
            route: PATIENTS_ROUTE,
            enum: RouteEnum.Attendances,
            icon: <PeopleAltOutlinedIcon/>,
            text: "Attendances"
        },
    ]
    const log = (a: any, b: any) => {
        console.log(a, b)
        return ''
    }
    return (
        <Box>
            {token ? (
                <Box>
                    <Box sx={{position: 'fixed', top: '0', left: '0', zIndex: 2, color: '#000', width: '100%'}}>
                        <Box sx={{
                            maxWidth: '650px',
                            margin: '0 auto',
                            padding: '15px 25px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button color="primary" onClick={() => logout()} sx={{
                                '&:hover': {color: '#fff', backgroundColor: '#757ce8'},
                                backgroundColor: '#fff',
                                minWidth: 'fit-content'
                            }}>
                                <LogoutIcon/>
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            position: 'fixed',
                            justifyContent: 'center',
                            width: '100%',
                            bottom: '0'
                        }}>
                        <Box boxShadow={2} sx={{
                            width: 'clamp(200px, 100%, 650px)',
                            justifyContent: 'space-between',
                            backgroundColor: 'var(--backgroundGray)',
                            borderRadius: '10px 10px 0 0',
                            padding: '10px 15px',
                            display: 'flex'
                        }}>
                            {
                                routes.map((r, i) =>
                                    <Link key={i} to={r.route} style={{textDecoration: 'none'}}>
                                        <Button
                                            className='routeIcon'
                                            name="home"
                                            sx={isSelected(r.enum) ? activeStyle : inactiveStyle}>
                                            {r.icon}
                                            <span style={{
                                                paddingLeft: '4px',
                                                maxWidth: 'calc(100% - 24px)',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}> {isSelected(r.enum) && r.text}</span>
                                        </Button>
                                    </Link>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

export default Navbar;
