import {Box, Grid} from "@mui/material";
import defaultImage from "../images/download.png";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import HeightIcon from '@mui/icons-material/Height';
import {theme} from "../utils/theme";
import {IAttendance} from "../utils/enums/attendances";

type Props = {
    attendanceData: IAttendance
}

const AttendanceCard = (props: Props) => {

    return (
        <Box borderRadius={theme.shape.borderRadius} bgcolor={'#fff'} sx={{boxShadow: 'var(--global-box-shadow)'}}>
            <Grid container spacing={0} sx={{
                borderRadius: '15px',
                width: '100%',
                padding: '20px',
                margin: '20px 0',
                justifyContent: 'space-between',
                position: 'relative',
                backgroundColor: "var(--background-end-rgb)"
            }}>
                <Grid item sx={{height: '150px'}}>
                    <Box sx={{height: '100%', backgroundColor: 'lightgray', borderRadius: '10px', overflow: 'hidden'}}>
                        <img
                            style={{
                                objectFit: 'cover',
                                height: '100%'
                            }}
                            src={defaultImage}
                            alt="attendance"
                        />
                    </Box>
                </Grid>
                <Grid item
                      sx={{fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', margin: '0'}}>
                    <Grid container spacing={0} sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        letterSpacing: '1px',
                        margin: '0',
                        width: '100%',
                        borderBottom: 'solid 1px #ededed'
                    }}>
                        <Box sx={{fontSize: '17px'}}>
                            {/* Attendance's Name */}
                            {props.attendanceData.first_name}
                        </Box>
                    </Grid>
                    <Grid container spacing={0} sx={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                        letterSpacing: '1px',
                        margin: '0',
                        borderBottom: 'solid 1px #ededed',
                        width: '100%'
                    }}>
                        <Grid xs={3} item sx={{minWidth: 'fit-content', padding: '10px 20px 10px 0'}}>
                            <AccountBoxIcon/> {props.attendanceData.age}
                        </Grid>
                        <Grid xs={3} item sx={{minWidth: 'fit-content', padding: '10px 20px 10px 0'}}>
                            {(props.attendanceData.gender === 'Male') ? (<MaleIcon/>) : (
                                <FemaleIcon/>)} {props.attendanceData.gender}
                        </Grid>
                        <Grid xs={3} item sx={{minWidth: 'fit-content', padding: '10px 20px 10px 0'}}>
                            <MonitorWeightIcon/> {props.attendanceData.weight} KG
                        </Grid>
                        <Grid xs={3} item sx={{minWidth: 'fit-content', padding: '10px 20px 10px 0'}}>
                            <HeightIcon/> {props.attendanceData.height} CM
                        </Grid>
                    </Grid>
                    <Box sx={{fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', margin: '0', width: '100%'}}>
                        <Box sx={{padding: '0 0 20px'}}>
                            Total Appointments: {props.attendanceData.total_appointments}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AttendanceCard;
